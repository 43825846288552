<template>
  <div class="table matrix-table">
    <div class="table-head d-none d-md-block">
      <v-row class="muted">
        <v-col cols="5">{{ $tc('forms.subcompetency', 1) }}</v-col>
        <v-col cols="2" class="scale-col text-md-center">{{
          $t('forms.scale')
        }}</v-col>
        <v-col cols="5" v-if="value.allowComment">
          {{ $t('comment') }}
        </v-col>
      </v-row>
    </div>

    <div class="table-body">
      <v-row v-for="item in sortedItems" :key="`matrix_item_${item.id}`">
        <v-col cols="12" md="5">
          <template v-if="localize(item.subtitle) !== '' && !printView">
            {{ localize(item.title) }}
            <v-tooltip bottom max-width="500" content-class="tooltip-wrapper">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-information</v-icon>
              </template>
              <div v-html="localize(item.subtitle)"></div>
            </v-tooltip>
          </template>
          <template v-else>
            {{ localize(item.title) }}
          </template>

          <!-- <v-alert
            v-if="!item.subtextAsTooltip && localize(item.subtitle) !== ''"
            dense
            text
            class="mt-2"
            icon="mdi-information"
            rounded
          >
            <div
              v-html="
                localize(item.subtitle).replace(/(?:\r\n|\r|\n)/g, '<br />')
              "
            ></div>
          </v-alert> -->
        </v-col>
        <v-col cols="12" md="2">
          <div class="scale-wrapper justify-md-center">
            <div>
              <item-scale
                v-model="item.value"
                :availableValues="availableValues"
                :anchors="value.anchors"
                :showAnchors="value.subtitleAsTooltip"
                :readOnly="readOnly"
                :printView="printView"
                :value-state="item.valueState"
                @change="itemChanged(item.id, item.value)"
              ></item-scale>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="5" v-if="value.allowComment">
          <div class="textbox-wrapper">
            <v-textarea
              v-if="!printView"
              v-model="item.comment"
              hide-details="auto"
              filled
              auto-grow
              rows="1"
              :disabled="readOnly"
              :hint="getCommentHint(item)"
              :persistent-hint="getCommentHint(item) !== ''"
              :placeholder="$t('comment')"
              @keyup="itemCommentChanged(item)"
              @blur="leaveField(item)"
              @focus="enterField(item)"
            ></v-textarea>

            <div v-else class="item-comment">{{ item.comment }}</div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import elementMixin from '@/mixins/form-element.js';
import itemScale from '@/components/forms/questionnaire/form-elements/item-scale.vue';

import { feedbackEnums } from '@/enums/feedbacks.js';
import { errorCodes } from '@/enums/errorCodes.js';
import { mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  mixins: [elementMixin],

  data() {
    return {
      availableValues: [],
      autoSaveInterval: 30000,
      autoSaveTimer: null
    };
  },

  computed: {
    sortedItems() {
      const itemsCopy = [...this.value.items];
      return itemsCopy.sort((a, b) => {
        if (a.index < b.index) {
          return -1;
        } else if (a.index > b.index) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  },

  mounted() {
    const values = [];
    for (let i = this.value.minValue; i <= this.value.maxValue; i++) {
      values.push(i);
    }

    this.availableValues = values;
  },

  beforeDestroy() {
    if (this.autoSaveTimer) {
      window.clearTimeout(this.autoSaveTimer);
    }
  },

  methods: {
    ...mapActions({
      saveItem: 'feedbacks/saveMatrixItemValue',
      saveItemComment: 'feedbacks/saveComment'
    }),

    enterField(item) {
      const vm = this;
      vm.autoSaveTimer = window.setInterval(() => {
        vm.saveComment(item.id, item.comment);
      }, vm.autoSaveInterval);
    },

    getCommentHint(item) {
      if (item.commentState !== feedbackEnums.valueState.COPIED) {
        return '';
      }

      return this.$t('feedbacks.copiedValueInfo');
    },

    itemCommentChanged(item) {
      if (
        item.commentState === feedbackEnums.valueState.COPIED
      ) {
        item.commentState = feedbackEnums.valueState.COPIED_AND_UPDATED;
      }
    },

    leaveField(item) {
      this.saveComment(item.id, item.comment);

      if (this.autoSaveTimer) {
        window.clearTimeout(this.autoSaveTimer);
      }
    },

    async itemChanged(itemId, itemValue) {
      const result = await this.saveItem({
        feedbackId: this.$route.params.id,
        elementId: this.value.id,
        itemId: itemId,
        value: itemValue,
        accessToken: this.$route.params.accessToken
      });

      const item = this.value.items.find((x) => x.id === itemId);
      if (item && item.valueState === feedbackEnums.valueState.COPIED) {
        item.valueState = feedbackEnums.valueState.COPIED_AND_UPDATED;
      }

      if (
        result.errorCode &&
        result.errorCode === errorCodes.errorCodes.UNEXPECTED_EXCEPTION
      ) {
        this.$emit('errorOnSave');
      }
    },

    async saveComment(itemId, comment) {
      const result = await this.saveItemComment({
        feedbackId: this.$route.params.id,
        elementId: this.value.id,
        itemId: itemId,
        comment: comment,
        accessToken: this.$route.params.accessToken
      });

      if (
        result.errorCode &&
        result.errorCode === errorCodes.errorCodes.UNEXPECTED_EXCEPTION
      ) {
        this.$emit('errorOnSave');
      }
    }
  },

  components: {
    itemScale
  }
};
</script>

<style lang="scss" scoped>
.scale-wrapper {
  display: flex;

  .textbox-wrapper {
    flex: 1;
  }
}

.question-matrix-table::v-deep {
  table {
    table-layout: fixed;
  }
}

.question-col {
  width: 35%;
}

.scale-col {
  width: 250px;
}

tbody tr td {
  vertical-align: middle;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.tooltip-wrapper {
  white-space: pre-wrap;
}

.theme--light.v-data-table {
  margin-top: 1em;

  thead > tr > th {
    border-bottom: 4px solid var(--element-background-secondary) !important;
  }
}

.item-comment {
  white-space: pre-line;
}
</style>
